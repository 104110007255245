// Dependencies
import { useEffect } from "react";

// Image Imports
import about from "../img/about.png";

// Style Imports
import './about.css';

// Functional Component
function About() {

    useEffect(() => {
        document.title = 'Our Story – WInterview';
    }, []);

    return (
        <div className="about">
            <div className="t">
                <h2>Our Story</h2>
                <h1>From frustration to <span className='win'>innovation</span></h1>
                <img src={about} />
            </div>
            <div className="m">
                
                <h3>
                WInterview all started when two students from the University of Waterloo were struggling to find resources to help us prepare for their co-op interviews. Frustrated by the lack of support and modern solutions, we decided to take matters into our own hands and create an app that would revolutionize the interview preparation process.
                <br></br><br></br>
                With no experience coding mobile apps, we dove headfirst into the world of app development, teaching ourselves everything we needed to know to make our vision a reality. We poured our hearts and souls into the project, working tirelessly day and night to bring our dream to life.
                <br></br><br></br>
                Finally, after countless hours of hard work, WInterview was born - but the journey was far from over. We still had to do our interviews! With the help of our new personal career coaches, we both crushed our interviews and secured amazing roles at industry-leading companies. We’ve continued to use WInterview to help us get bigger and better roles at the organizations of our dreams.
                <br></br><br></br>
                Today, WInterview has helped hundreds of students and professionals improve their interview skills and achieve their career goals. It's a testament to the power of determination, resilience, and innovation in the face of adversity.
                <br></br><br></br>
                So if you're ready to take your interview skills to the next level, join the WInterview community today. Let's revolutionize the way we prepare for interviews and unleash our full potential - together.
                </h3>
            </div>
        </div>
    );
}

export default About;