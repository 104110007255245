// Dependencies
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";

// Page Imports
import { Home, Features, About, Downloads, Contact, PrivacyPolicy, NotFound } from './pages/index.js';

// Component Imports
import { Header, Footer } from './components/header_footer';

// Style Imports
import './App.css';

// Functional Component
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => { window.scrollTo(0, 0); }, [pathname]);
  return null;
}

// Functional Component
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className="hdr-blk-area" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/about" element={<About />} />
        <Route path="/download" element={<Downloads />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

// Export component
export default App;