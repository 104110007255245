// Dependencies
import { useState, useEffect } from "react";
import { NavLink, useLocation, withRouter } from "react-router-dom";

// Style Imports
import './header.css';
import './footer.css';

// Image Imports
import logo_small from "../img/logo_small.png";

// SVG Imports
import burger from "../svg/hamburger.svg";
import instagram from "../svg/instagram.svg";
import twitter from "../svg/twitter.svg";
import linkedin from "../svg/linkedin.svg";
import youtube from "../svg/youtube.svg";

// Functional Component
export function Header() {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        function handleScroll() {
            if (window.pageYOffset > 50) setScrolled(true);
            else setScrolled(false);
        }
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    const page = useLocation();
    const [currPage, setCurrPage] = useState(page);
    useEffect(()=>{
        setCurrPage(page.pathname);
    },[page]);

    const [menuOpen, setMenuOpen] = useState(false);
    let location = useLocation();
    useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    return (
        <>
            <div className={"header" + ((currPage === '/home' || currPage === '/' ) ? (scrolled ? " hme scrolled" : " hme") : "")}>
                <div className="l"><NavLink to="/"><img src={logo_small} alt="" /></NavLink></div>
                <div className="m">
                    <NavLink to="/features" className={(n) => (n.isActive ? "hdr-d active" : "hdr-d")}>
                        <h1>Features</h1>
                    </NavLink>
                    <NavLink to="/about" className={(n) => (n.isActive ? "hdr-d active" : "hdr-d")}>
                        <h1>About</h1>
                    </NavLink>
                    <NavLink to="/downloads" className={(n) => (n.isActive ? "hdr-d active" : "hdr-d")}>
                        <h1>Downloads</h1>
                    </NavLink>
                    <NavLink to="/contact" className={(n) => (n.isActive ? "hdr-d active" : "hdr-d")}>
                        <h1>Contact</h1>
                    </NavLink>
                </div>
                <div className="r">
                    <button className="join" onClick={()=>{window.open('https://apps.apple.com/ca/app/winterview/id1532631548','_blank')}}>Join WInterview</button>
                    <button className="menu" onClick={()=>{setMenuOpen(!menuOpen)}}><img src={burger} /></button>
                </div>
            </div>
            { (menuOpen) &&
            <div className="mbl-menu">
                    <a href="https://apps.apple.com/ca/app/winterview/id1532631548" target="_blank" className="mbl-menu-nl join">
                        <h1>Join WInterview</h1>
                    </a>
                    <NavLink to="/" className={(n) => (n.isActive ? "mbl-menu-nl active" : "mbl-menu-nl")}>
                        <h1>Home</h1>
                    </NavLink>
                    <NavLink to="/features" className={(n) => (n.isActive ? "mbl-menu-nl active" : "mbl-menu-nl")}>
                        <h1>Features</h1>
                    </NavLink>
                    <NavLink to="/about" className={(n) => (n.isActive ? "mbl-menu-nl active" : "mbl-menu-nl")}>
                        <h1>About</h1>
                    </NavLink>
                    <NavLink to="/downloads" className={(n) => (n.isActive ? "mbl-menu-nl active" : "mbl-menu-nl")}>
                        <h1>Downloads</h1>
                    </NavLink>
                    <NavLink to="/contact" className={(n) => (n.isActive ? "mbl-menu-nl active" : "mbl-menu-nl")}>
                        <h1>Contact</h1>
                    </NavLink>
            </div>}
        </>
    );
};

// Functional Component
export function Footer() {
    return (
        <div className="footer">
            <div className="l"><NavLink to="/"><img src={logo_small} alt="" /></NavLink></div>
            <div className="m">
                <h2>Follow us</h2>
                <a href="https://instagram.com/winterview.ca/" target="_blank"><img src={instagram} /></a>
                <a href="https://ca.linkedin.com/company/winterview" target="_blank"><img src={linkedin} /></a>
            </div>
            <div className="r">
                <NavLink to="/privacy" className="mbl">Privacy Policy</NavLink>
                <h2>© 2023 WInterview</h2>
                <div className="dvdr" />
                <NavLink to="/privacy">Privacy Policy</NavLink>
            </div>
        </div>
    );
}