// Dependencies
import { useEffect } from "react";

// Style Imports
import "./home.css";

// PNG Imports
import staring from "../img/staring.png";
import lying_down from "../img/lying_down.png";
import practice_png from "../img/practice.png";
import learn_png from "../img/learn.png";
import master_png from "../img/master.png";

// SVG Imports
import TargetSVG from "../svg/target.svg";
import BooksSVG from "../svg/books.svg";
import MedalSVG from "../svg/medal.svg";
import confident_icon from "../svg/confident.svg";
import adaptable_icon from "../svg/bolt.svg";
import eye_icon from "../svg/eye.svg";
import professional_icon from "../svg/bowtie.svg";
import body_icon from "../svg/person.svg";
import expert_icon from "../svg/glasses.svg";

// Functional Component
function Home(props) {

    useEffect(()=>{
        document.title = 'Win that Interview – WInterview';
    },[]);

    return (
        <div className="home">
            <div className="t">
                <h1><span className='win'>Win</span> that Interview.</h1>
                <h2>An A.I.-powered career coach in your pocket.<br></br>Practice smarter. Interview better. Dream bigger.</h2>
                <button className="join" onClick={()=>{window.open('https://apps.apple.com/ca/app/winterview/id1532631548','_blank')}}>Join Now</button>
                <img src={lying_down} />
                <div className="vid"/>
            </div>
            <div className="m">
                <h1>Practice smarter.<br></br><span className='win'>Master</span> the art of interviewing.</h1>
                <div className="cards big">
                    <div className="card">
                        <img className="icon" src={TargetSVG} />
                        <h1>Practice</h1>
                        <h2>Position-specific questions in the same environment as your interview.</h2>
                        <img className="i" src={practice_png} alt="" />
                    </div>
                    <div className="card">
                        <img className="icon" src={BooksSVG} />
                        <h1>Learn</h1>
                        <h2>Analyze key indicators & use in-depth insights to improve.</h2>
                        <img className="learn i" src={learn_png} alt="" />
                    </div>
                    <div className="card">
                        <img className="icon" src={MedalSVG} />
                        <h1>Master</h1>
                        <h2>Perfect your answer to every question and become an expert at interviewing.</h2>
                        <img className="i" src={master_png} alt="" />
                    </div>
                </div>
            </div>
            <div className="m">
                <h1>Interview better.<br></br><span className='win'>Unlock</span> your career potential.</h1>
                <div className="cards small">
                    <div className="card">
                        <img className="icon" src={confident_icon} />
                        <h1>Confidence</h1>
                        <h2>Build confidence through familiarity with the interview experience.</h2>
                    </div>
                    <div className="card">
                        <img className="icon" src={expert_icon} />
                        <h1>Expertise</h1>
                        <h2>Master industry keywords & perfect your interview answers.</h2>
                    </div>
                    <div className="card">
                        <img className="icon" src={adaptable_icon} />
                        <h1>Adaptability</h1>
                        <h2>Prepare for all situations to be able to handle any interview question.</h2>
                    </div>
                    <div className="card">
                        <img className="icon" src={professional_icon} />
                        <h1>Professionalism</h1>
                        <h2>Minimize ineffective speech & demonstrate career readiness.</h2>
                    </div>
                    <div className="card">
                        <img className="icon" src={eye_icon} />
                        <h1>Eye Contact</h1>
                        <h2>Learn to use proper eye contact to engage your interviewer.</h2>
                    </div>
                    <div className="card">
                        <img className="icon" src={body_icon} />
                        <h1>Body Language</h1>
                        <h2>Train on how to use your body language to help you shine.</h2>
                    </div>
                </div>
            </div>
            <div className="b">
                <img src={staring} />
                <h1>Use WInterview for <span className='win'>free</span></h1>
                <h2>Start winning your dream jobs today.</h2>
                <button className="join" onClick={()=>{window.open('https://apps.apple.com/ca/app/winterview/id1532631548','_blank')}}>Join Now</button>
            </div>
        </div>
    );
}

export default Home;