// Dependencies
import { useEffect } from "react";

// Image Imports
import notfound from '../img/404.png';

// Style Imports
import './404.css';

// Functional Component
function NotFound() {
    useEffect(()=>{
        document.title = 'Oops! – WInterview';
    },[]);

    return(
        <div className="notfound">
            <img src={notfound} />
            <h1>Oops! This page doesn't exist.</h1>
            <h3>You may have mistyped the address or the page may have moved.</h3>
        </div>
    );
}

export default NotFound;