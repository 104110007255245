// Dependencies
import { useEffect } from 'react';

// Style Imports
import './privacy.css';

// Functional Component
function PrivacyPolicy() {
    useEffect(()=>{
        document.title = 'Privacy Policy – WInterview';
    },[]);

    return(
        <div className="privacypol">
            <div className="t">
                <h1><span className="win">Privacy</span> Policy</h1>
            </div>
            <div className="b">
                <h3 className="up-date">Last modified: 09/21/2020<br /><br /></h3>
                <h3>
                    The ‘Winterview’ app is a Free app - this SERVICE is provided at no cost and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible through the mobile app or winterview.ca unless otherwise defined in this Privacy Policy.
                </h3>
                <br />
                <h2>Information Collection and Use</h2>
                <h3>
                    For a better experience, while using our Service, we require you to provide us with certain personally identifiable information, limited to First Name and Email Address, and may ask you to provide us with other personally identifiable information including but not limited to Employment Information, Program Major, and Education Institution. The information that we request will be retained by us and used as described in this privacy policy.
                    <br /><br />
                    The app does use third party services that may collect information used to identify you. Links to privacy policy of third-party service providers used by the app:
                    <br /><br />
                    • <a href="https://cloud.google.com/terms/data-processing-addendum" target="_blank">Google Cloud Platform (GCP) Data Processing and Security</a>
                    <br /><br />
                    • <a href="https://firebase.google.com/support/privacy" target="_blank">Google Analytics for Firebase</a>
                    <br /><br />
                    • <a href="https://firebase.google.com/support/privacy" target="_blank">Firebase Crashlytics</a>
                </h3>
                <br />
                <h2>Log Data</h2>
                <h3>
                    We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
                </h3>
                <br />
                <h2>Cookies</h2>
                <h3>
                    Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                    This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being
                    sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                </h3>
                <br />
                <h2>Service Providers</h2>
                <h3>
                    We may employ third-party companies and individuals due to the following reasons:
                    <br /><br />
                    • To facilitate our Service;
                    <br /><br />
                    • To provide the Service on our behalf;
                    <br /><br />
                    • To perform Service-related services; or
                    <br /><br />
                    • To assist us in analyzing how our Service is used.
                    <br /><br />
                    We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                </h3>
                <br />
                <h2>Security</h2>
                <h3>
                    We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. No virtual practice readings are uploaded to any online server, and all interview analysis is completed and discarded safely on-device. The only data stored to our servers are final indicator grades (limited to Eye Contact, Confidence, Keywords, Answer Length, Word Speed, Volume) and final overall scores. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                </h3>
                <br />
                <h2>Interview Data</h2>
                <h3>
                    <b>(a) General</b>
                    <br /><br />
                    We use Apple’s Native TrueDepth API and Speech Transcription API exclusively for the purpose of providing user feedback on mock virtual interviews. These technologies are only used by the application if the user specifically chooses to participate in a mock virtual interview. Once the interview is over, or if the app closes, the functions provided by these APIs are no longer used.
                    <br /><br />
                    <b>(b) Collection</b>
                    <br /><br />
                    We utilize Apple’s TrueDepth API to collect basic information about users’ eye movement and distance to their device during the course of a mock interview. Eye position is estimated consistently throughout the mock interview and formatted into three categories (‘Camera Gaze’, ‘On-Screen’, and ‘Off-Screen’) and the exact coordinates of a user’s eye gaze are discarded once the application determines which of these three areas an individual is looking at. We also track the appearance of a limited number of facial expressions, none of which are user-identifiable. This information is limited to quantitative data – no visual information is collected. The application has absolutely no recording functionality and cannot record user’s interview audio or video input.
                    The app employs Apple’s Speech Transcription API to synthesize user’s responses to mock interview questions. When an interviewee is asked a question, their full answer is transcripted.
                    <br /><br />
                    <b>(c) Use</b>
                    <br /><br />
                    We use the data collected from Apple’s TrueDepth API and Speech Transcription API to provide feedback on users’ mock interviews. Eye contact, facial expression, and distance data are put through scoring algorithms to generate scores from 0-100.
                    We use Speech Transcription data to analyze occurrences of positive and negative keywords (as well as profanity) and determine word speed.
                    <br /><br />
                    <b>(d) Disclosure</b>
                    <br /><br />
                    We do not disclose personal data to any third parties. Only aggregated, non-identifiable data may be transmitted to external services (with consent) to help us improve our user experience. All data is encrypted and protected so as to provide authenticated users access to only their own mock interview analysis history.
                    <br /><br />
                    <b>(e) Retention and Deletion</b>
                    <br /><br />
                    Exact estimates of a user’s eye gaze position are categorized as ‘Camera Gaze’, ‘On-Screen’, or ‘Off-Screen’, after which the estimated position coordinates are discarded. After a mock interview ends, or if the application closes before this occurs, this categorized data is also deleted. Users are only able to upload their final Eye Contact score in order to track their progression in this skill domain. It is not possible to access any other data collected using Apple’s TrueDepth API than this final Eye Contact score after a mock interview ends (or the app closes) or to transmit any other mock interview analysis information to an online server or third-party service.
                    Facial expression and distance data are deleted once the mock interview ends, or if the application closes before this occurs.
                    Transcriptions of user answers are deleted once the mock interview ends, or if the application closes before this occurs.
                    All user progress data is deleted permanently when a user deletes their account.
                </h3>
                <br />
                <h2>Links to Other Sites</h2>
                <h3>
                    This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                </h3>
                <br />
                <h2>Children's Privacy</h2>
                <h3>
                    These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take necessary action to remove their account and all associated data.
                </h3>
                <br />
                <h2>Changes to This Privacy Policy</h2>
                <h3>
                    We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. This policy is effective as of 2020-09-21.
                </h3>
                <br />
                <h2>Contact Us</h2>
                <h3>
                    If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@winterview.ca.
                </h3>
            </div>
        </div>
    );
}

export default PrivacyPolicy;