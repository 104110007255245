// Dependencies
import { useEffect } from 'react';

// Style Imports
import './contact.css';

// Functional Component
function Contact() {

    useEffect(() => {
        document.title = 'Contact Us – WInterview';
    }, []);

    return (
        <div className="contact">
            <div className="t">
                <h2>Contact Us</h2>
                <h1>Let's get <span className='win'>in touch</span></h1>
            </div>
            <div className="m">
                <div className="card">
                    <h1>General</h1>
                    <a href="mailto:welcome@winterview.ca">welcome@winterview.ca</a>
                </div>
                <div className="card">
                    <h1>Media</h1>
                    <a href="mailto:welcome@winterview.ca">pr@winterview.ca</a>
                </div>
                <div className="card">
                    <h1>Jobs & Careers</h1>
                    <a href="mailto:welcome@winterview.ca">careers@winterview.ca</a>
                </div>
                <div className="card">
                    <h1>Support</h1>
                    <a href="mailto:welcome@winterview.ca">support@winterview.ca</a>
                </div>
            </div>
        </div>
    );
}

export default Contact;