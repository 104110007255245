// Dependencies
import { useState, useEffect } from "react";

// Image Imports
import iosLogo from "../img/ios_logo.png";
import androidLogo from "../img/android_logo.png";
import desktopLogo from "../img/desktop_logo.png";

// SVG Imports
import appleIcon from "../svg/apple.svg";
import androidIcon from "../svg/android.svg";
import desktopIcon from "../svg/desktop.svg";

// Style Imports
import './downloads.css';

// Functional Component
function Downloads() {

    // Fetch latest description from app store
    const [iosDescription, setIosDescription] = useState("It only takes one WInterview. WInterview is the world's only virtual interview preparation platform. Participate in simulated Virtual Interviews, receive instant feedback on numerous Indicators, learn from unique post-interview Insights, track your interview skills' Progress, browse Careers and job listings, and more.");
    useEffect(() => {
        document.title = 'Downloads – WInterview';

        async function fetchDescription() {
            const bundleId = 'com.winterviewOfficial-a-1-0-0';
            const response = await fetch(`https://itunes.apple.com/lookup?bundleId=${bundleId}`);
            const data = await response.json();
            if (data.resultCount > 0) {
                setIosDescription(data.results[0].description);
            }
        };
        fetchDescription();
    }, []);
    
    // Return layout
    return (
        <div className="downloads">
            <div className="t">
                <h2>Downloads</h2>
                <h1>Use WInterview for <span className='win'>free</span></h1>
            </div>
            <div className="m">
                <div className="card">
                    <div className="top">
                        <img src={appleIcon} />
                        <h1>iOS</h1>
                    </div>
                    <div className="bot">
                        <img src={iosLogo} />
                        <div className="r">
                            <h1>Version 1.0.1</h1>
                            <h3>{iosDescription}</h3>
                            <a href='https://apps.apple.com/ca/app/winterview/id1532631548' target='_blank'>Open App Store</a>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="top">
                        <img src={androidIcon} />
                        <h1>Android</h1>
                    </div>
                    <div className="bot">
                        <img src={androidLogo} />
                        <div className="r">
                            <h1>Unavailable</h1>
                            <h3>WInterview has not yet been released for Android devices.</h3>
                            <button className="hidden">Open Play Store</button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="top">
                        <img src={desktopIcon} />
                        <h1>Desktop</h1>
                    </div>
                    <div className="bot">
                        <img src={desktopLogo} />
                        <div className="r">
                            <h1>Unavailable</h1>
                            <h3>WInterview has not yet been released for Mac, Windows, or Linux.</h3>
                            <button className="hidden">Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Downloads;