// Dependencies
import { useState, useEffect } from "react";

// Image Imports

// SVG Imports
import interview_icon from "../svg/interview_icon.svg";
import indicators_icon from "../svg/indicator_icon.svg";
import insights_icon from "../svg/insights_icon.svg";
import progress_icon from "../svg/progress_icon.svg";

// Style Imports
import './features.css';

// Functional Component
function Features() {

    useEffect(()=>{
        document.title = 'Features – WInterview';
    },[]);

    const [currFeature, setCurrFeature] = useState(0);
    
    function getFeatureText(feature) {
        switch (feature) {
            case 0:
                return `On WInterview, you can tailor your preparation experience to the specific positions you’re interviewing for.\n\nOur interactive practice interviewers will ask question customized to the role you are targeting, while engaging you in a realistic simulation of an interview environment. Speak directly to your phone and listen to questions as they are asked out loud.\n\nWInterview provides you with an interactive practice experience that closely mirrors the questions and environment of your upcoming interview, ensuring you are fully prepared.`;
            case 1:
                return "WInterview features a powerful tracking system for key interview indicators.\n\nThroughout your practice interview several key indicators are monitored and graded to provide you with valuable feedback on your performance, helping you identify areas for improvement and fine-tune your interview skills.\n\nWhether it’s maintaining better eye contact, speaking more confidently, using the right keywords, or speaking at an appropriate pace and volume, WInterview provides you with the detailed analysis you need to win your interview.";
            case 2:
                return "WInterview provides you with in-depth insights for each practice interview, powered by AI.\n\nInsights include actionable suggestions on how to improve key indicators and guidance on areas where you performed well. Insights also analyze each of your answers and highlights how they could be strengthened, as well as identifying what you did well and what you should continue doing.\n\nThese detailed, actionable feedback points give you the tools to make targeted improvements to your interview skills and elevate your career potential.";
            case 3:
                return "WInterview lets you track your performance in practice interviews over time, across all indicators.\n\nGet a clear picture of your strengths and areas for improvement, see how your skills are developing, and get the power to to continuously sharpen your interviewing expertise.\n\nSeeing your skills progress over time serves as a source of motivation and confidence, further encouraging you to continue improving.";
            default:
                return "";
        }
    }

    function getFeatureTitle(feature) {
        switch (feature) {
            case 0:
                return(<h1>Your personal <span className='win'>interview coach</span></h1>);
            case 1:
                return(<h1>Analyze key <span className='win'>indicators</span></h1>);
            case 2:
                return(<h1>Learn from in-depth <span className='win'>insights</span></h1>);
            case 3:
                return(<h1>Track your <span className='win'>progress</span></h1>);
            default:
                return(<h1>Your personal interview <span className='win'>coach</span></h1>);
        }
    }

    return (
        <div className="features">
            <div className="t">
                <h2>Features</h2>
                {getFeatureTitle(currFeature)}
            </div>
            <div className="select">
                <button className={"feature-card" + ((currFeature === 0) ? " s" : "")} onClick={()=>{setCurrFeature(0)}}>
                    <img src={interview_icon} />
                    <h1>Interview</h1>
                </button>
                <button className={"feature-card" + ((currFeature === 1) ? " s" : "")} onClick={()=>{setCurrFeature(1)}}>
                    <img src={indicators_icon} />
                    <h1>Indicators</h1>
                </button>
                <button className={"feature-card" + ((currFeature === 2) ? " s" : "")} onClick={()=>{setCurrFeature(2)}}>
                    <img className="insights" src={insights_icon} />
                    <h1>Insights</h1>
                </button>
                <button className={"feature-card" + ((currFeature === 3) ? " s" : "")} onClick={()=>{setCurrFeature(3)}}>
                    <img src={progress_icon} />
                    <h1>Progress</h1>
                </button>
            </div>
            <div className="b">
                <img src="" />
                <h3>{getFeatureText(currFeature)}</h3>
            </div>
        </div>
    );
}

export default Features;